import './OfficesAndCashiers.scss'

import { Libraries, LoadScript } from '@react-google-maps/api'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getGoogleMapsApiKeyLkConfig } from '../../lib/lkWebConfig'
import AccessibleTabs, { AccessibleTab } from '../AccesibleTabs/AccessibleTabs'
import CashiersFinder from './CashiersFinder/CashiersFinder'
import { CashiersProps } from './CashiersFinder/CashiersWithAgreements/CashiersWithAgreements'
import Offices, { OfficesWithInfoCardProps } from './Offices/Offices'

export type OfficesAndCashiersProps = OfficesWithInfoCardProps & CashiersProps

const googleMapsLibraries: Libraries = ['places']

const OfficesAndCashiers: FC<OfficesAndCashiersProps> = ({ offices, infoCard, cashiersWithAgreements }) => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)
  const [isLoaded, setIsLoaded] = useState(false)
  const tabs: AccessibleTab[] = useMemo(
    () => [
      { label: t('offices.tab.offices'), value: 'offices' },
      { label: t('offices.tab.cashiers'), value: 'cashiers' },
    ],
    [t],
  )
  const [currentTab, setCurrentTab] = useState<AccessibleTab>(tabs[0])

  const googleMapsApiKey = getGoogleMapsApiKeyLkConfig()

  useEffect(() => {
    const url = new URL(window?.location.href)
    const tabParam = url?.searchParams?.get('tab') || ''
    const defaultTab = tabs.find((tab) => tab.value === tabParam) || tabs[0]
    setCurrentTab(defaultTab)
  }, [tabs])

  useEffect(() => {
    const updateTab = async () => {
      const url = new URL(window?.location.href)
      url.searchParams.set('tab', currentTab.value)
      window.history.replaceState({}, '', url)
    }
    updateTab()
  }, [currentTab])

  return (
    <div className="container offices">
      <AccessibleTabs tabs={tabs} onChange={setCurrentTab} selectedId={currentTab?.value} />
      <LoadScript
        googleMapsApiKey={googleMapsApiKey}
        libraries={googleMapsLibraries}
        language={language}
        loadingElement={<div className="maps-loading">{t('loadingIndicator.loading')}</div>}
        onLoad={() => setIsLoaded(true)}
      >
        {isLoaded && currentTab.value === 'offices' && <Offices offices={offices} infoCard={infoCard} />}
        {isLoaded && currentTab.value === 'cashiers' && (
          <CashiersFinder cashiersWithAgreements={cashiersWithAgreements} />
        )}
      </LoadScript>
    </div>
  )
}

export default OfficesAndCashiers
