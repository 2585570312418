export interface Cashier extends Omit<google.maps.places.PlaceResult, 'geometry'> {
  isLk: boolean
  geometry: {
    location: {
      lat: number
      lng: number
    }
  }
}

const useCashiersUtils = () => ({
  filterCashiers: (cashiers: Cashier[]): Cashier[] => cashiers.filter((c) => c.isLk),

  directionsUrl: (lat: number, lng: number) =>
    /iPhone|iPad|iPod/i.test(window?.navigator?.userAgent)
      ? `https://maps.apple.com/?daddr=${lat},${lng}`
      : `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
})

export default useCashiersUtils
